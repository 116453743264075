import Vue from "vue";
import App from "./App.vue";

// Vendor components
import { BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import VueVideoPlayer from 'vue-video-player';
import AudioRecorder from 'audio-recorder-vue'
import VueRecord from '@codekraft-studio/vue-record'

// Custom components
import Countdown from '@/components/Countdown'

//Router
import router from "./router/index";

Vue.config.productionTip = false;
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueVideoPlayer)
Vue.use(AudioRecorder)
Vue.use(VueRecord)

Vue.component('countdown', Countdown)

Number.prototype.round = function(places) {
  return +(Math.round(this + "e+" + places)  + "e-" + places);
}

import '@/scss/_app.scss'
import 'video.js/dist/video-js.css'

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
