<template>
    <h2 class="mt-3 mb-3">
        <b-badge>
            <span class="">{{formattedMinutes}}</span>:<span>{{formattedSeconds}}</span>
        </b-badge>
    </h2>
</template>

<script>
export default {
    name: "Countdown",
    props: {
        totalSeconds:Number
    },
    data() {
        return {
            remainingSeconds : this.totalSeconds
        }
    },
    computed : {
        formattedMinutes: function(){
            return Math.floor(this.remainingSeconds/60);
        },
        formattedSeconds: function(){
            return String(this.remainingSeconds%60).padStart(2, '0');
        }
    },
    mounted(){
      this.updateCountdown();
    },
    methods : {
        updateCountdown:function(){
            this.remainingSeconds--;
            if(this.remainingSeconds === 0){
                this.$emit("complete");
            }else{
                setTimeout(this.updateCountdown, 1000);
            }
        }
    }
}
</script>

<style scoped>

</style>
