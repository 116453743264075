import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/a-propos",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/",
    name: "Task list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
            import(/* webpackChunkName: "about" */ "../views/LanguageTaskList.vue"),
  },
  {
    path: "/etudes-en-cours",
    name: "Study list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
            import(/* webpackChunkName: "about" */ "../views/StudyList.vue"),
  },
  {
    path: "/tasks/:taskType/:taskVersion/:participantCode?",
    name: "Task",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
            import(/* webpackChunkName: "about" */ "../views/TaskLoader.vue"),
  },
  {
    path: "/studies/:studyCode",
    name: "Study",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
            import(/* webpackChunkName: "about" */ "../views/StudyLoader.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
